@import "settings";
@import "tools/px-em";
@import "tools/map-reach";
@import "tools/fluid-type";
@import "tools/media-query";
@import "tools/strip-unit";
@import "tools/aspec-ratio";
@import "tools/math";
@import "tools/clearfix";

//@import "reset";
@import "vendor/bootstrap_material/mdb";


* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

body {
    -webkit-touch-callout: none;
    /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;
    /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;
    /* prevent copy paste, to allow, change 'none' to 'text' */
    font-family: $default_font;
    font-size: 12px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
    width: 100%;
}

html,
body {
    background-color: #003780;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'bebas_neuebold', Helvetica, Arial;
}

.scan-qr,
.btn--in,
.btn--out {
    .fas {
        font-size: 32px;
        vertical-align: top;
        margin-top: 0px;
        margin-right: 17px;
        line-height: 20px;
    }
}

.row-100-h {
    min-height: calc(100vh - 164px);
}

.screen {
    padding-top: 82px;
    padding-bottom: 82px;
}

.btn--again.is-hidden,
.alert.is-hidden,
.screen.is-hidden {
    display: none;
}

.dropdown-toggle::after {
    display: none;
}

.navbar {
    .dropdown-menu .dropdown-header {
        padding: 10px;
    }
    .navbar-nav {
        flex-direction: row;
    }
    .dropdown-toggle {
        padding-left: 10px;
        padding-right: 5px;

        .fa {
            font-size: 20px;
            margin-left: 7px;
        }

        .text--username {
            font-size: 13px;
            vertical-align: top;
            margin-top: 6px;
            display: none;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
    }
    .language-switcher {
        margin-bottom: 0;
        a {
            padding: 5px;
        }
    }
}

.input-group-append .btn {
    margin: 0;

    .fa-camera {
        font-size: 21px;
    }
}

.navbar {
    min-height: 62px;
}

.navbar-brand {
    position: fixed;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
}

.nav--back,
.nav--close,
.nav--delete {
    padding: 0;
    width: 30px;
    font-size: 22px;
}

@keyframes zoomEffect {
    0% {
        transform: scale(1, 1)
    }
    50% {
        transform: scale(0.8, 0.8)
    }
    100% {
        transform: scale(1, 1)
    }
}

.nav--upload {
    display: none !important;
    font-size: 18px;

    &.is-visible {
        display: block !important;
    }

    .error-icon {
        display: none;
    }

    &.failed {
        color: #FFD84A !important;

        .normal-icon {
            color: #fff !important;
        }
        .error-icon {
            animation: 1s ease 0s normal none infinite running zoomEffect;
            display: inline-block;
        }
    }
}

.nav--close {
    text-align: right;
}


#login {
    padding-top: 0;
    padding-bottom: 0;

    .logo {
        width: 200px;
        height: auto;
    }

    .col {
        max-width: 400px;
    }

    .card-header {
        background: #008FDA;
        padding: 40px 0;
    }
}

#photos {
    .photo {
        display: block;
        margin: 0 0 30px;

        img {
            width: 100%;
            height: auto;
        }
    }
    .card {
        margin: 80px 0;
    }
}

#photo-view .current-img,
#photo-view canvas {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 62px;
}

.photos .addFile {
    position: absolute;
    left: -9999px;
}

#upload {
    .progress {
        margin: 10px 0 0;
        max-width: 100%;
        height: 4px;
        width: 100%;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #C3C6C8;
        border-radius: 0;

        &::-webkit-progress-value {
            background: #69D745;
        }
    }
    .loaded {
        font-size: 12px;
        padding: 4px 0 0;
        margin: 0;
    }

    .photos {
        img {
            width: auto;
            max-height: 100px;
        }
        .row {
            padding: 20px 0 0;
            border-top: 1px solid #e9ecef;

            @include media-breakpoint-up(md) {
                padding: 20px 0;
            }

            &:first-child {
                border-top: none;
            }
        }
    }

    .status {
        font-weight: 700;
        margin: 0 0 20px;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
    .failed .status {
        color: #cc0000;
    }
}

/*
#login .card {
    position: absolute;
    width: 90%;
    top: 50%;
    transform: translateY(-50%);
}*/

/* Portrait layout (default) */

.app {
    background: url(../img/logo.png) no-repeat center top;
    /* 170px x 200px */
    position: absolute;
    /* position in the center of the screen */
    left: 50%;
    top: 50%;
    height: 50px;
    /* text area height */
    width: 225px;
    /* text area width */
    text-align: center;
    padding: 180px 0px 0px 0px;
    /* image height is 200px (bottom 20px are overlapped with text) */
    margin: -115px 0px 0px -112px;
    /* offset vertical: half of image height and text area height */
    /* offset horizontal: half of text area width */
}

/* Landscape layout (with min-width) */

@media screen and (min-aspect-ratio: 1/1) and (min-width:400px) {
    .app {
        background-position: left center;
        padding: 75px 0px 75px 170px;
        /* padding-top + padding-bottom + text area = image height */
        margin: -90px 0px 0px -198px;
        /* offset vertical: half of image height */
        /* offset horizontal: half of image width and text area width */
    }
}

h1 {
    font-size: 24px;
    font-weight: normal;
    margin: 0px;
    overflow: visible;
    padding: 0px;
    text-align: center;
}

.event {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    margin: 0px 30px;
    padding: 2px 0px;
}

.event.listening {
    background-color: #333333;
    display: block;
}

.event.received {
    background-color: #4B946A;
    display: none;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.4;
    }
    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.4;
    }
    to {
        opacity: 1.0;
    }
}

.blink {
    animation: fade 3000ms infinite;
    -webkit-animation: fade 3000ms infinite;
}

.nav-wrapper {
    background: #00B4E3;
}

#uploadphoto-button,
#addphoto-button {
    position: relative;
    margin: 0 0 0 10px;
    float: left;

    a {
        position: relative;
        z-index: 3;
    }

    button {
        margin: 0;
        position: relative;
        z-index: 3;
    }

    input {
        position: absolute;
        z-index: 4;
        opacity: 0.0001;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

nav .nav-wrapper .brand-logo {
    width: 130px;
    color: #1D3F75 !important;
    background: url(../images/logo.svg) no-repeat 0 50%;
    background-size: contain;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

nav ul a {
    color: #1D3F75;
}

#qrscan video {
    display: block;
    width: 100%;
    height: 100vw;
    background: #ccc;
}

#qrscan #preview {
    display: block;
    width: 100% !important;
    height: auto !important; //100vw;
    background: #ccc;
}

#camera #camerapreview {
    display: block;
    width: 100% !important;
    height: auto !important; //100vw;
    background: #ccc;
}

#take-photo {
    position: fixed;
    z-index: 6;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #cc000090;
    color: #fff;
    border: 1px solid #fff;
}

.videoSource {
    position: fixed;
    z-index: 6;
    right: 10px;
    top: 100px;
    background: #ffffff;
    color: #000;
    border: none;
}

#toggle-flash-qr,
#toggle-flash {
    position: fixed;
    z-index: 6;
    left: 10px;
    top: 100px;
    background: #ffffff;
    color: #000;
    border: none;
}

#camera .take {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: #fff;
    opacity: 0;
    transition: opacity 50ms;

    &.flash {
        transition: none;
        opacity: 1;
    }
}
